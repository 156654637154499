var exports = {};
var test = {
  foo: {}
};
var $Object = Object;
exports = function hasProto() {
  return {
    __proto__: test
  }.foo === test.foo && !({
    __proto__: null
  } instanceof $Object);
};
export default exports;